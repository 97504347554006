import './input.css';

import React from 'react';

const Input = ({ placeholder, type = 'text', className, button = <></>, attributes }) => {
    return (
        <div className='form__input'>
            <input 
                className={`input ${className} ${button && '_with-button' || ''}`}
                type={type} 
                placeholder={placeholder}
                {...attributes}
            />
            <div className='form__input-button'>
                {button}
            </div>
        </div>
    )
}

export default Input;