import './report.css';

import React, { useContext, useState } from 'react';

import { Theme } from '../../context';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getFile, getReportResultId } from '../../modules/parserItem/actions';
import moment from 'moment';
import Icon from '../../components/icon'
import 'moment/locale/ru';
import ButtonCustom from '../../components/button'
import { reguest } from '../../modules/initData/api';

const Timeline = () => {
    
    const theme = useContext(Theme);

    const [fileState, setFileState] = useState(false);
    
    const { statusResultID } = useSelector(state => state.parserItem);

    const dispatch = useDispatch();

    return (
        <div className='report'>
            <table>
                <tbody>
                    <tr>
                        <th>Дата</th>
                    </tr>
                    {theme.modalTimeline && theme.modalTimeline.data && theme.modalTimeline.data.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>
                                    <a href="#" target="_blank" onClick={e => {
                                        e.preventDefault();

                                        if(statusResultID == "IDLE"){
                                            dispatch(getReportResultId({
                                                id: item.id
                                            }))
                                        }

                                    }}>{moment(item.created).format("DD.MM.YYYY hh:mm")}</a>
                                </td>
                                <td>
                                    {moment().diff(moment(item.created), 'hours') >= 1 && (
                                        <ButtonCustom attributes={{
                                            disabled: fileState,
                                            onClick: () => {
                                                if(!fileState){
                                                    setFileState(true);
                                                    dispatch(getFile({
                                                        id: item.id,
                                                        date: moment(item.created).format("DD.MM.YYYY hh:mm")
                                                    }))
                                                    setTimeout(function(){
                                                        setFileState(false);
                                                    }, 2000);   
                                                }
                                                // theme.setModalReport(true);
                                            }
                                        }}>
                                            <Icon name="download" />
                                        </ButtonCustom>
                                    )}
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default Timeline;