import {handleActions} from "redux-actions";
import {combineReducers} from "redux";
import {
    updateParserItem,
    updateParserItemSuccess,
    updateParserItemFailure,
    updateParserItemIdle,
    addParserItem,
    addParserItemSuccess,
    addParserItemFailure,
    addParserItemIdle,
    deleteParserItem,
    deleteParserItemSuccess,
    deleteParserItemFailure,
    deleteParserItemIdle,
    startParserItem,
    startParserItemSuccess,
    getReport,
    getReportSuccess,
    getReportFailure,
    getReportIdle,
    getReportTimeline,
    getReportTimelineSuccess,
    getReportTimelineFailure,
    getReportTimelineIdle,
    getReportTimelineClear,
    getReportResultId,
    getReportResultIdSuccess,
    getReportResultIdFailure,
    getReportResultIdIdle,
} from "./actions";

const statusStart = handleActions(
    {
        [startParserItem]: () => 'LOADING',
        [startParserItemSuccess]: () => 'SUCCESS',
    },
    'IDLE'
)

const dataStart = handleActions(
    {
        [startParserItemSuccess]: (state, action) => action.payload
    },
    null
)

const idStart = handleActions(
    {
        [startParserItem]: (state, action) => {
            return [state, action.payload.id]
        },
        [startParserItemSuccess]: () => null,
    },
    null
);

const statusUpdate = handleActions(
    {
        [updateParserItemSuccess]: () => "SUCCESS",
        [updateParserItem]: () => "LOADING",
        [updateParserItemFailure]: () => "FAILURE",
        [updateParserItemIdle]: () => "IDLE",
    },
    'IDLE'
);


const statusReport = handleActions(
    {
        [getReportSuccess]: () => "SUCCESS",
        [getReport]: () => "LOADING",
        [getReportFailure]: () => "FAILURE",
        [getReportIdle]: () => "IDLE",
    },
    'IDLE'
);

const idReport = handleActions(
    {
        [getReport]: (state, action) => {
            return [state, action.payload.id]
        },
        [getReportSuccess]: () => null,
    },
    null
);

const dataReport = handleActions(
    {
        [getReportSuccess]: (state, action) => action.payload
    },
    null
)

const statusAdd = handleActions(
    {
        [addParserItemSuccess]: () => "SUCCESS",
        [addParserItem]: () => "LOADING",
        [addParserItemFailure]: () => "FAILURE",
        [addParserItemIdle]: () => "IDLE",
    },
    'IDLE'
);

const statusDelete = handleActions(
    {
        [deleteParserItemSuccess]: () => "SUCCESS",
        [deleteParserItem]: () => "LOADING",
        [deleteParserItemFailure]: () => "FAILURE",
        [deleteParserItemIdle]: () => "IDLE",
    },
    'IDLE'
);

const statusTimeline = handleActions(
    {
        [getReportTimelineSuccess]: () => "SUCCESS",
        [getReportTimeline]: () => "LOADING",
        [getReportTimelineFailure]: () => "FAILURE",
        [getReportTimelineIdle]: () => "IDLE",
    },
    'IDLE'
);

const reportTimeline = handleActions(
    {
        [getReportTimelineSuccess]: (state, action) => action.payload,
        [getReportTimelineClear]: () => null,
    },
    null
);

const statusResultID = handleActions(
    {
        [getReportResultIdSuccess]: () => "SUCCESS",
        [getReportResultId]: () => "LOADING",
        [getReportResultIdFailure]: () => "FAILURE",
        [getReportResultIdIdle]: () => "IDLE",
    },
    'IDLE'
);

const reportResultID = handleActions(
    {
        [getReportResultIdSuccess]: (state, action) => action.payload,
    },
    null
);

const parserItem = combineReducers({
    statusUpdate,
    statusAdd,
    statusDelete,
    statusStart,
    dataStart,
    statusReport,
    dataReport,
    idReport,
    idStart,
    statusTimeline,
    reportTimeline,
    statusResultID,
    reportResultID
});

export {parserItem};
