import { call, put, takeEvery, all } from "redux-saga/effects";

import { reguest } from "./api";

import {
  getInitData,
  getInitDataSuccess,
  getInitDataFailure,
  getInitDataIdle,
  setSettings,
  setSettingsSuccess,
  setSettingsFailure
} from "./actions";

function* getInitDataSaga(action) {
  try {

    const [count, settings] = yield all([
      call(reguest, {
        route: "query/count/0",
      }),
      call(reguest, {
        route: "settings/get/work_period/",
      }),
    ]);

    yield put(
      getInitDataSuccess({
        count,
        settings
      })
    );

  } catch (error) {
    console.log(error);

    yield put(getInitDataFailure(error));
  }
}

function* setSettingsSaga(action) {
  try {

    const { payload } = action;

    const response = yield call(reguest, {
      route: "settings/set/work_period/"+payload.period+"/",
    });

    yield put(
      setSettingsSuccess(response)
    );

  } catch (error) {
    console.log(error);

    yield put(setSettingsFailure(error));
  }
}

export function* getInitDataWatcher() {
  yield takeEvery(getInitData.toString(), getInitDataSaga);
  yield takeEvery(setSettings.toString(), setSettingsSaga);
}
