import React from 'react';

import Icon from '../../components/icon';
import Input from '../../components/input';
import Button from '../../components/button';

const UI = () => {
    return (
        <>
            <div style={{ background: '#bbb' }}>
                <Icon name="download" />
                <Icon name="edit" />
                <Icon name="delete" />
                <Icon name="search" width={14} height={14} />
                <Icon name="setting" width={40} height={40} />
                <Icon name="plus" width={12} height={12} color={'#ffffff'} />
                <Icon name="plus" width={6} height={6} color={'#4285B4'} />
                <Icon name="close" width={10} height={10} />
                <Icon name="remove" width={10} height={10} color={'#D83636'} />
                <Icon name="chevron-down" width={14} height={8} />
            </div>
            <Input attributes={{
                onChange: (e) => {
                    console.log(e.target.value);
                }
            }} />
            <Button>
                <Icon name="download" />
            </Button>
        </>
    )
}

export default UI;