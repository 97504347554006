export const version = 'v1';

export const token = 'User oxhvDBoqlT1NVsbPFcccvh8HFx5V8p2kxrYwz3ArHMU=';


export const domain = 'https://wikiparser.linups.online';

export const work_period = {
    '1': 'Каждый час',
    '2': 'Каждый день',
    '3': 'Каждый неделю'
}

export const work_period_options = [
    {
        label: 'Каждый час',
        value: 1
    },{
        label: 'Каждый день',
        value: 2
    },{
        label: 'Каждый неделю',
        value: 3
    }
]
