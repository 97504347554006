import {createActions} from "redux-actions";

const {
    updateParserItem,
    updateParserItemSuccess,
    updateParserItemFailure,
    updateParserItemIdle,
    addParserItem,
    addParserItemSuccess,
    addParserItemFailure,
    addParserItemIdle,
    deleteParserItem,
    deleteParserItemSuccess,
    deleteParserItemFailure,
    deleteParserItemIdle,
    startParserItem,
    startParserItemSuccess,
    getReport,
    getReportSuccess,
    getReportFailure,
    getReportIdle,
    getReportTimeline,
    getReportTimelineSuccess,
    getReportTimelineFailure,
    getReportTimelineIdle,
    getReportTimelineClear,
    getReportResultId,
    getReportResultIdSuccess,
    getReportResultIdFailure,
    getReportResultIdIdle,
    getFile,
    getFileSuccess
} = createActions(
    "UPDATE_PARSER_ITEM",
    "UPDATE_PARSER_ITEM_SUCCESS",
    "UPDATE_PARSER_ITEM_FAILURE",
    "UPDATE_PARSER_ITEM_IDLE",
    "ADD_PARSER_ITEM",
    "ADD_PARSER_ITEM_SUCCESS",
    "ADD_PARSER_ITEM_FAILURE",
    "ADD_PARSER_ITEM_IDLE",
    "DELETE_PARSER_ITEM",
    "DELETE_PARSER_ITEM_SUCCESS",
    "DELETE_PARSER_ITEM_FAILURE",
    "DELETE_PARSER_ITEM_IDLE",
    "START_PARSER_ITEM",
    "START_PARSER_ITEM_SUCCESS",
    'GET_REPORT',
    'GET_REPORT_SUCCESS',
    'GET_REPORT_FAILURE',
    'GET_REPORT_IDLE',
    'GET_REPORT_TIMELINE',
    'GET_REPORT_TIMELINE_SUCCESS',
    'GET_REPORT_TIMELINE_FAILURE',
    'GET_REPORT_TIMELINE_IDLE',
    'GET_REPORT_TIMELINE_CLEAR',
    'GET_REPORT_RESULT_ID',
    'GET_REPORT_RESULT_ID_SUCCESS',
    'GET_REPORT_RESULT_ID_FAILURE',
    'GET_REPORT_RESULT_ID_IDLE',
    'GET_FILE',
    'GET_FILE_SUCCESS'
);


export {
    updateParserItem,
    updateParserItemSuccess,
    updateParserItemFailure,
    updateParserItemIdle,
    addParserItem,
    addParserItemSuccess,
    addParserItemFailure,
    addParserItemIdle,
    deleteParserItem,
    deleteParserItemSuccess,
    deleteParserItemFailure,
    deleteParserItemIdle,
    startParserItem,
    startParserItemSuccess,
    getReport,
    getReportSuccess,
    getReportFailure,
    getReportIdle,
    getReportTimeline,
    getReportTimelineSuccess,
    getReportTimelineFailure,
    getReportTimelineIdle,
    getReportTimelineClear,
    getReportResultId,
    getReportResultIdSuccess,
    getReportResultIdFailure,
    getReportResultIdIdle,
    getFile,
    getFileSuccess
};
