import './button.css';

import React from 'react';

const Button = ({ children, type = 'button', className, attributes }) => {
    return (
        <button
            type={type}
            className={`button ${className}`}
            {...attributes}
        >
            {children}
        </button>
    )
}

export default Button;