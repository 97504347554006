import React, { useContext, useEffect, useRef, useState } from 'react';

import { Button, Popconfirm } from 'antd';

import ButtonCustom from '../../../../components/button';
import Icon from '../../../../components/icon'

import moment from 'moment';
import { Theme } from '../../../../context';
import { useSelector, useDispatch } from 'react-redux';
import { deleteParserItem, deleteParserItemIdle, getReport, getReportTimeline, startParserItem } from '../../../../modules/parserItem/actions';
import { updateParserItem, updateParserItemIdle } from '../../../../modules/parserItem/actions'
import 'moment/locale/ru';

const ParserItem = ({ title, id, query_items, last_parse_time, last_update_time, created, emails, lang }) => {

    const parserItemRef = useRef(null);
    const refTooltip = useRef(null);

    // const [showDelete, setShowDelete] = useState(false);

    const dispatch = useDispatch();

    const theme = useContext(Theme);

    const { statusDelete, statusUpdate, statusReport, idReport, idStart, statusTimeline } = useSelector(state => state.parserItem);
    
    const onClick = e => {
        // console.log(e.target.parentElement.parentElement.parentElement);
        // if(e.target.parentElement.parentElement && e.target.parentElement.parentElement.parentElement && e.target.parentElement.parentElement.parentElement.classList.contains('_delete')){
            
        // } else {
        //     setShowDelete(false);
        // }
        // if(refTooltip.current.contains(e.target)){
        //     refTooltip.current.contains(e.target)
        // } else {
        //     setShowDelete(false);
        // }
    };

    useEffect(() => {
        // document.addEventListener('click', onClick);
        // return () => document.removeEventListener('click', onClick);
    },[])

    return (
        <div className='table__row _item' ref={parserItemRef}>
            <div className='table__td _name'>
                ({lang}) {title}
            </div>
            <div className='table__td _keywords'>
                {query_items.join(', ')}
            </div>
            <div className='table__td _report'>
                {moment(last_update_time.Time).format("DD.MM.YYYY") !== '01.01.0001' && (
                    <ButtonCustom attributes={{
                        disabled: statusReport == 'LOADING' && true || false,
                        onClick: () => {
                            if(statusReport == "IDLE"){
                                dispatch(getReport({
                                    id: id
                                }))
                            }
                            // theme.setModalReport(true);
                        }
                    }}>
                        {statusReport == 'LOADING' && idReport.indexOf(id) >= 0 && 'Загрузка' || <Icon name="download" />}
                    </ButtonCustom>
                )}
            </div>
            <div className='table__td _last-change'>
                {moment(last_parse_time.Time).format("DD.MM.YYYY") == '01.01.0001' && 'Еще не было изменений' || (
                    <>
                        {moment(last_parse_time.Time).locale('ru').startOf('minute').fromNow()}, <br/>
                        {moment(last_parse_time.Time).format("DD.MM.YYYY")}
                    </>
                )}
            </div>
            <div className='table__td _last-scan'>
                {moment(last_update_time.Time).format("DD.MM.YYYY") == '01.01.0001' && 'Еще не было сканирований' || (
                    <>
                        {moment(last_update_time.Time).locale('ru').startOf('minute').fromNow()}, <br/>
                        {moment(last_update_time.Time).format("DD.MM.YYYY")}
                    </>
                )}
            </div>
            <div className='table__td _date-create'>
                {moment(created).format("DD.MM.YYYY")}
            </div>
            <div className='table__td _emails'>
                {emails && emails.length > 0 && emails.map((item, index) => {
                    return (
                        <div className='share-email' key={index}>
                            {item}
                            <ButtonCustom attributes={{
                                onClick: () => {
                                    if(statusUpdate == 'IDLE'){

                                        let removeEmails = emails;

                                        removeEmails.splice(index, 1);

                                        dispatch(updateParserItem({
                                            body: {
                                                id: id,
                                                title: title,
                                                query_items: query_items,
                                                emails: removeEmails
                                            }
                                        }))
                                    }
                                }
                            }}>
                                <Icon name="remove" width={10} height={10} color="#D83636" />
                            </ButtonCustom>
                        </div>
                    )
                })}
            </div>
            <div className='table__td _edit'>
                <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                    <ButtonCustom attributes={{
                        onClick: () => {
                            if(statusTimeline == 'IDLE'){
                                dispatch(getReportTimeline({
                                    id: id
                                }))
                            }
                        }
                    }}>
                        <Icon name="time" width={24} height={28} color={'#4285B4'} />
                    </ButtonCustom>
                    <ButtonCustom attributes={{
                        onClick: () => {
                            theme.setModalEdit({
                                title: title,
                                query_items: query_items,
                                emails: emails,
                                id,
                            });
                        }
                    }}>
                        <Icon name="edit" width={24} height={24}/>
                    </ButtonCustom>
                </div>
            </div>
            <div className='table__td _delete'>
                <Popconfirm
                    title="Удалить?"
                    description=""
                    // placement="topCenter"
                    onConfirm={() => {
                        if(statusDelete == 'IDLE'){
                            parserItemRef.current.style.display = 'none';
                            dispatch(deleteParserItem({
                                id
                            }));
                        }
                    }}
                    onCancel={() => {
                        
                    }}
                    okText="Да"н
                    cancelText="Нет"
                >
                    <Button type="text" style={{ padding: 0 }}>
                        <Icon name="delete" width={24} height={24} />
                    </Button>
                </Popconfirm>
                <div style={{ position: 'relative' }}>
                    {/* {showDelete && <div className='tooltip' ref={refTooltip}>
                        <ButtonCustom className={'_default'} attributes={{
                            onClick: () => {
                                if(statusDelete == 'IDLE'){
                                    parserItemRef.current.style.display = 'none';
                                    dispatch(deleteParserItem({
                                        id
                                    }));
                                }
                            }
                        }}>
                            Да
                        </ButtonCustom>
                        <ButtonCustom className={'_default'} attributes={{
                            onClick: () => {
                                setShowDelete(!showDelete);
                            }
                        }}>
                            Нет
                        </ButtonCustom>
                    </div>}
                    <ButtonCustom attributes={{
                        onClick: () => {
                            setShowDelete(!showDelete);
                        }
                    }}>
                        <Icon name="delete" width={24} height={24} />
                    </ButtonCustom> */}
                </div>
            </div>
            <div className='table__td _start'>
                {idStart && idStart.indexOf(id) >= 0 && (
                    <Icon name="time" width={24} height={28} color={'#4285B4'} />
                ) || (
                    <ButtonCustom attributes={{
                        onClick: () => {
                            dispatch(startParserItem({
                                id
                            }));
                        }
                    }}>
                        <Icon name="play" width={24} height={28} />
                    </ButtonCustom>
                )}
            </div>
        </div>
    )
}

export default ParserItem;