import { call, put, takeEvery, all } from "redux-saga/effects";

import { reguest } from "../initData/api";

import {
  getParser,
  getParserSuccess,
  getParserFailure,
  getParserIdle,
} from "./actions";

function* getParserSaga(action) {
  try {

    const { payload } = action;

    const { offset, count = 20 } = payload;

    const [response] = yield all([
      call(reguest, {
        route: "query/list/" + offset + "/" + count + "/0",
      }),
    ]);

    yield put(getParserSuccess(response));

  } catch (error) {
    console.log(error);

    yield put(getParserFailure(error));
  }
}

export function* getParserWatcher() {
  yield takeEvery(getParser.toString(), getParserSaga);
}
