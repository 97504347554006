import React, { useContext } from 'react';
import Modal from '../../components/modal';
import { Theme } from '../../context';
import Create from '../forms/create';
import Edit from '../forms/edit';
import Setting from '../forms/setting';
import Report from '../report';
import Timeline from '../timeline';
import moment from 'moment';
import 'moment/locale/ru';

const Modals = () => {

    const theme = useContext(Theme);

    return (
        <>
            <Modal 
                state={theme.modalSetting} 
                toggle={theme.setModalSetting}
                title={'Настройки'}
            >
                <Setting />
            </Modal>
            <Modal
                state={theme.modalCreate} 
                toggle={theme.setModalCreate}
                title={'Новое задание'}
            >
                <Create />
            </Modal>
            <Modal
                state={theme.modalEdit} 
                toggle={theme.setModalEdit}
                title={'Редактировать задание'}
            >
                <Edit />
            </Modal>
            <Modal
                state={theme.modalReport} 
                toggle={theme.setModalReport}
                title={'Отчет ' + ( theme.modalReport && theme.modalReport.data && theme.modalReport.data.result && moment(theme.modalReport.data.result.created).format("DD.MM.YYYY"))}
                className='_report'
            >
                <Report />
            </Modal>
            <Modal
                state={theme.modalTimeline} 
                toggle={theme.setModalTimeline}
                title={'Timeline'}
                className='_report'
            >
                <Timeline />
            </Modal>
        </>
    )
}

export default Modals;