import './home.css';

import React, { useContext } from 'react';
import Button from '../../components/button';
import Icon from '../../components/icon';
import { Theme } from '../../context';
import Search from '../../layout/forms/search';
import Parser from '../../layout/parser';

const Home = () => {

    const theme = useContext(Theme);

    return (
        <div className='page _home'>
            <div className='page__header'>
                <div className='container'>
                    <div className='page__title'>
                        <h1>Парсер</h1>
                    </div>
                    <span className='online'>Online</span>
                    <div className='page__header-actions'>
                        <Button attributes={{
                            onClick: () => {
                                theme.setModalSetting(true);
                            }
                        }}>
                            <Icon name={'setting'} width={40} height={40} />
                        </Button>
                        <Button attributes={{
                            onClick: () => {
                                theme.setModalCreate(true);
                            }
                        }} className={'_create'}>
                            <Icon name={'plus'} width={10} height={10} />
                            Создать
                        </Button>
                    </div>
                </div>
            </div>
            <div className='page__search'>
                <div className='container'>
                    <Search />
                </div>
            </div>
            <div className='page__content'>
                <div className='container'>
                    <Parser />
                </div>
            </div>
        </div>
    )
}

export default Home;