import './select.css';

import React from 'react';

import Icon from '../icon';

const Select = ({ className, options, attributes, icon = <Icon name="chevron-down" width={14} height={8} /> }) => {
    return (
        <div className='form__select'>
            <select 
                className={`select ${className}`}
                {...attributes}
            >
                {options && options.map((item, index) => {
                    return (
                        <option key={index} value={item.value && item.value || item}>{item.label && item.label || item}</option>
                    )
                })}
            </select>
            <div className='form__select-icon'>
                {icon}
            </div>
        </div>
    )
}

export default Select;