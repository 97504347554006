import React from 'react';

import IconsSVG from '../../assets/icons/icons.svg'

const Icon = ({ name, width = 24, height = 24, color }) => {
    return (
        <svg
            width={width}
            height={height}
            style={{ color }}
            aria-hidden='true'
            focusable='false'
        >
            <use href={`${IconsSVG}#${name}`}></use>
        </svg>
    )
}

export default Icon;