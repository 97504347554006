import React, { useEffect, createContext, useState } from 'react';
import Modals from './layout/modals';

import Home from './pages/home';
import UI from './pages/ui';

import { getInitData } from './modules/initData/actions';
import { useSelector, useDispatch } from 'react-redux';

import {
    createBrowserRouter,
      RouterProvider,
} from "react-router-dom";
import { Theme } from './context';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "ui",
    element: <UI />,
  },
]);

const App = () => {

    const dispatch = useDispatch();

    const [modalSetting, setModalSetting] = useState(false);
    const [modalCreate, setModalCreate] = useState(false);
    const [modalEdit, setModalEdit] = useState(false);
    const [modalReport, setModalReport] = useState(false);
    const [modalTimeline, setModalTimeline] = useState(false);

    useEffect(() => {
      dispatch(getInitData());
    }, [])
    
    return (
        <>
            <Theme.Provider value={{
                modalCreate, 
                setModalCreate,
                modalSetting, 
                setModalSetting,
                modalEdit, 
                setModalEdit,
                modalReport, 
                setModalReport,
                modalTimeline, 
                setModalTimeline
            }}>
                <RouterProvider router={router} />
                <Modals />
            </Theme.Provider>
        </>
    )
}

export default App;