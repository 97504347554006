import './report.css';

import React, { useContext } from 'react';
import { Tabs } from 'antd';

import moment from 'moment';

import { report } from '../../config';
import { Theme } from '../../context';
import 'moment/locale/ru';
const { TabPane } = Tabs;

const Report = () => {
    
    const theme = useContext(Theme);

    return (
        <div className='report'>
            <Tabs
                defaultActiveKey="1"
            >
                <TabPane tab={'Результат'} key="1">
                    {theme.modalReport && theme.modalReport.data.result && theme.modalReport.data.result.items.length > 0 && (
                        <table>
                            <tbody>
                                <tr>
                                    <th>Заголовок</th>
                                    <th>Ссылка</th>
                                </tr>
                                {theme.modalReport && theme.modalReport.data.result && theme.modalReport.data.result.items && theme.modalReport.data.result.items.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{item.post_title}</td>
                                            <td>
                                                <a href={item.post_url} target="_blank">Ссылка</a>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    ) || (
                        <h2>Ничего не найдено</h2>
                    )}
                </TabPane>
                <TabPane tab="Добавленные" key="2" disabled={theme.modalReport.data && (!theme.modalReport.data.added || !theme.modalReport.data.added.length) && true || false}>
                    <table>
                        <tbody>
                            <tr>
                                <th>Заголовок</th>
                                <th>Ссылка</th>
                            </tr>
                            {theme.modalReport && theme.modalReport.data.added && theme.modalReport.data.added.length > 0 && theme.modalReport.data.added.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{item.post_title}</td>
                                        <td>
                                            <a href={item.post_url} target="_blank">{moment(item.post_updated).format("DD.MM.YYYY")}</a>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </TabPane>
                <TabPane tab="Удаленныe" key="3" disabled={theme.modalReport.data && (!theme.modalReport.data.removed || !theme.modalReport.data.removed.length) && true || false}>
                    <table>
                        <tbody>
                            <tr>
                                <th>Заголовок</th>
                                <th>Ссылка</th>
                            </tr>
                            {theme.modalReport && theme.modalReport.data.removed && theme.modalReport.data.removed.length > 0 && theme.modalReport.data.removed.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{item.post_title}</td>
                                        <td>
                                            <a href={item.post_url} target="_blank">{moment(item.post_updated).format("DD.MM.YYYY")}</a>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </TabPane>
            </Tabs>
        </div>
    )
}

export default Report;