import { call, put, takeEvery, all } from "redux-saga/effects";

import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

import { reguest } from "../initData/api";

import {
  updateParserItem,
  updateParserItemSuccess,
  updateParserItemFailure,
  updateParserItemIdle,
  addParserItem,
  addParserItemSuccess,
  addParserItemFailure,
  addParserItemIdle,
  deleteParserItem,
  deleteParserItemSuccess,
  deleteParserItemFailure,
  deleteParserItemIdle,
  startParserItem,
  startParserItemSuccess,
  getReport,
  getReportSuccess,
  getReportFailure,
  getReportIdle,
  getReportTimelineSuccess,
  getReportTimelineFailure,
  getReportTimeline,
  getReportResultId,
  getReportResultIdSuccess,
  getReportResultIdFailure,
  getReportResultIdIdle,
  getFileSuccess,
  getFile,
} from "./actions";

function* addParserItemSaga(action) {
  try {

    const { payload } = action;

    const { body } = payload;

    const [response] = yield all([
      call(reguest, {
        route: "query/add",
        method: 'POST',
        body: body
      }),
    ]);

    yield put(addParserItemSuccess(response));

  } catch (error) {
    console.log(error);

    yield put(addParserItemFailure(error));
  }
}

function* updateParserItemSaga(action) {
  try {

    const { payload } = action;

    const { body } = payload;

    console.log(body);

    const [response] = yield all([
      call(reguest, {
        route: "query/update",
        method: 'POST',
        body: body
      }),
    ]);

    yield put(updateParserItemSuccess(response));

  } catch (error) {
    console.log(error);

    yield put(updateParserItemFailure(error));
  }
}

function* deleteParserItemSaga(action) {
  try {

    const { payload } = action;

    const { id } = payload;

    const [response] = yield all([
      call(reguest, {
        route: "query/delete/" + id,
      }),
    ]);

    yield put(deleteParserItemSuccess(response));

  } catch (error) {
    console.log(error);

    yield put(deleteParserItemFailure(error));
  }
}

function* startParserItemSaga(action) {
  try {

    const { payload } = action;

    const { id } = payload;

    const [response] = yield all([
      call(reguest, {
        route: "parser/parse/" + id,
      }),
    ]);

    yield put(startParserItemSuccess(response));

  } catch (error) {
    console.log(error);
  }
}

function* getReportSaga(action) {
  try {

    const { payload } = action;

    const { id } = payload;

    const [response] = yield all([
      call(reguest, {
        route: "result/latest/" + id,
      }),
    ]);

    yield put(getReportSuccess(response));

  } catch (error) {
    console.log(error);

    yield put(getReportFailure(error));
  }
}

function* getReportTimelineSaga(action) {
  try {

    const { payload } = action;

    const { id } = payload;

    const [response] = yield all([
      call(reguest, {
        route: "result/timelinelist/" + id,
      }),
    ]);

    yield put(getReportTimelineSuccess(response));

  } catch (error) {
    console.log(error);

    yield put(getReportTimelineFailure(error));
  }
}

function* getReportResultIDSaga(action) {
  try {

    const { payload } = action;

    const { id } = payload;

    const [response] = yield all([
      call(reguest, {
        route: "result/get/" + id,
      }),
    ]);

    yield put(getReportResultIdSuccess(response));

  } catch (error) {
    console.log(error);

    yield put(getReportResultIdFailure(error));
  }
}

function* getFileSaga(action) {
  try {

    const { payload } = action;

    const { id, date } = payload;

    const [response] = yield all([
      call(reguest, {
        route: 'result/file/'+id+'/',
        type: 'file'
      }),
    ]);

    if(response){
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(response);
      link.download = date + '.xlsx';
      link.click();
      link.remove();
    }

    yield put(getFileSuccess());

  } catch (error) {
    console.log(error);

    yield put(getFileSuccess(error));
  }
}

export function* parserItemWatcher() {
  yield takeEvery(addParserItem.toString(), addParserItemSaga);
  yield takeEvery(updateParserItem.toString(), updateParserItemSaga);
  yield takeEvery(deleteParserItem.toString(), deleteParserItemSaga);
  yield takeEvery(startParserItem.toString(), startParserItemSaga);
  yield takeEvery(getReport.toString(), getReportSaga);
  yield takeEvery(getReportTimeline.toString(), getReportTimelineSaga);
  yield takeEvery(getReportResultId.toString(), getReportResultIDSaga);
  yield takeEvery(getFile.toString(), getFileSaga); 
}
