import { version, token, domain } from "../../config";

export const reguest = ({ route, method = 'GET', body = null, type = null }) => {

    return new Promise((resolve, reject) => {

        var myHeaders = new Headers();
        myHeaders.append("Authorization", token);

        var requestOptions = {
            method: method,
            headers: myHeaders,
            redirect: 'follow'
        };

        if(body){
            requestOptions['body'] = JSON.stringify(body);
        }

        fetch(domain + '/' + version + '/' + route, requestOptions)
            .then(response => {
                let defaultResponse = response;
                if(!type){
                    defaultResponse = response.json();
                }
                if(type == 'file'){
                    defaultResponse = defaultResponse.blob();
                }
                return defaultResponse;
            })
            .then(result => {
                resolve(result)
            })
            .catch(error => {
                reject(error);
            });
    
    });

    
}