import './search.css';

import React, { useState } from "react";
import { useSelector, useDispatch } from 'react-redux';

import Input from '../../../components/input';
import Button from "../../../components/button/button";
import Icon from "../../../components/icon/icon";
import { setSearch } from '../../../modules/parser/actions';
import { getParser, getParserIdle, getParserClear } from '../../../modules/parser/actions';

const Search = () => {

    const [value, setValue] = useState('');

    const dispatch = useDispatch();

    const { status, result } = useSelector(state => state.parser);

    return (
        <div className="form _search">
            <Input
                className={'_default _search'}
                placeholder={'Поиск'}
                attributes={{
                    value: value,
                    onChange: (e) => {
                        setValue(e.target.value);
                    },
                    onKeyDown: (e) => {
                        if(e.key == 'Enter'){
                            if(value && value.length > 0){
                                dispatch(setSearch({
                                    value
                                }))
                            } else {
                                dispatch(getParserClear());
                                dispatch(getParser({
                                    offset: 0
                                }));
                            }
                        }
                    }
                }}
                button={
                    <Button attributes={{
                        onClick: () => {
                            dispatch(setSearch({
                                value
                            }))
                            if(status == 'IDLE' && value == ''){
                                dispatch(getParserClear());
                                dispatch(getParser({
                                    offset: 0
                                }));
                            }
                        }
                    }}>
                        <Icon 
                            name="search" 
                            width={14}
                            height={14}
                        />
                    </Button>
                }
            />
            {value && value.length > 0 && (
                <Button attributes={{
                    onClick: () => {
                        setValue('');
                        dispatch(getParserClear());
                        dispatch(getParser({
                            offset: 0
                        }));
                    }
                }}>
                    <Icon 
                        name="close" 
                        width={14}
                        height={14}
                    />
                </Button>
            )}
        </div>
    )
}

export default Search;