import {handleActions} from "redux-actions";
import {combineReducers} from "redux";
import {
    getParser,
    getParserSuccess,
    getParserFailure,
    getParserIdle,
    getParserClear,
    setSearch
} from "./actions";

const status = handleActions(
    {
        [getParserSuccess]: () => "SUCCESS",
        [getParser]: () => "LOADING",
        [getParserFailure]: () => "FAILURE",
        [getParserIdle]: () => "IDLE",
    },
    'IDLE'
);

const result = handleActions(
    {
        [getParserSuccess]: (state, action) => {
            return [
                ...state,
                ...action.payload.data
            ]
        },
        [setSearch]: (state, action) => {
            let filterState = [];
            if(state && state.length > 0){
                state.map(item => {
                    // console.log(item);
                    let stateFind = false;
                    if(item.title.toLowerCase().indexOf(action.payload.value.toLowerCase()) >= 0){
                        stateFind = true;
                    }
                    if(item.query_items){
                        item.query_items.map(el => {
                            if(el.toLowerCase().indexOf(action.payload.value.toLowerCase()) >= 0){
                                stateFind = true;
                            }
                        })
                    }
                    // console.log(stateFind, item.query_items, action.payload.value, item.query_items.indexOf(action.payload.value));
                    if(stateFind){
                        filterState.push(item);
                    }
                })
            }
            return filterState;
        },
        [getParserClear]: () => []
    },
    []
)

const parser = combineReducers({
    status,
    result
});

export {parser};
