import React, { useEffect, useState, useContext } from 'react';

import Input from '../../../components/input';
import Icon from '../../../components/icon';
import Button from '../../../components/button';

import { Theme } from '../../../context';

import { useSelector, useDispatch } from 'react-redux';

import { getParser, getParserClear, getParserIdle } from '../../../modules/parser/actions';
import { updateParserItem, updateParserItemIdle } from '../../../modules/parserItem/actions'

const Edit = () => {

    const theme = useContext(Theme);

    const dispatch = useDispatch();

    const defaultState = {
        id: '',
        title: '',
        query_items: '',
        emails: [''],
        user_id: 1
    }

    useEffect(() => {
        if(theme.modalEdit){
            setForm({
                user_id: 1,
                id: theme.modalEdit.id,
                title: theme.modalEdit.title,
                query_items: theme.modalEdit.query_items && theme.modalEdit.query_items.length > 0 && theme.modalEdit.query_items.join(', ') || '',
                emails: theme.modalEdit.emails && theme.modalEdit.emails || ['']
            })
        } else {
            setForm(defaultState);
        }
    }, [theme.modalEdit])
    
    const [validate, setValidate] = useState(false);

    const [form, setForm] = useState(defaultState);

    const { statusUpdate } = useSelector(state => state.parserItem);

    useEffect(() => {
        if(statusUpdate == 'SUCCESS'){
            dispatch(updateParserItemIdle());
            dispatch(getParserClear());
            dispatch(getParser({
                offset: 0
            }));
            theme.setModalEdit(false);
        }
    }, [statusUpdate])

    useEffect(() => {
        let state = true;
        if(!form.title.length){
            state = false;
        }
        if(!form.query_items.length){
            state = false;
        }
        // let stateEmail = false;
        // if(form.emails && form.emails.length > 0){
        //     form.emails.map(item => {
        //         if(item.length){
        //             stateEmail = true;
        //         }
        //     })
        // }
        // if(!stateEmail){
        //     state = false;
        // }
        setValidate(state);
    }, [form])

    return (
        <div className='form'>
            <div className='form__field'>
                <label>Название задания</label>
                <Input attributes={{
                    value: form.title,
                    onChange: (e) => {
                        setForm({
                            ...form,
                            title: e.target.value
                        })
                    }
                }} className={'_default'} placeholder={'Введите название'} />
            </div>
            <div className='form__field'>
                <label>Ключевые слова</label>
                <Input attributes={{
                    disabled: true,
                    value: form.query_items,
                    onChange: (e) => {
                        setForm({
                            ...form,
                            query_items: e.target.value
                        })
                    }
                }} className={'_default'} placeholder={'Введите ключевые слова'} />
            </div>
            <div className='form__field'>
                <div className='form__field-header'>
                    <label>Подписки</label>
                    <Button className={'_add-email'} attributes={{
                        onClick: () => {
                            setForm({
                                ...form,
                                emails: [
                                    ...form.emails,
                                    ''
                                ]
                            })
                        }
                    }}>
                        <Icon width={6} height={6} color="#4285B4" name="plus" />
                        Добавить
                    </Button>
                </div>
                <div className='form__field-list'>
                    <Input attributes={{
                        value: form.emails[0],
                        onChange: e => {
                            let emails = form.emails;
                            emails[0] = e.target.value;
                            setForm({
                                ...form,
                                emails: emails
                            })
                        }
                    }} className={'_default'} placeholder={'Введите email'} />
                    {form.emails && form.emails.length > 1 && form.emails.map((item, index) => {
                        if(index < 1){
                            return;
                        }
                        return (
                            <Input 
                                key={index}
                                className={'_default'} 
                                placeholder={'Введите email'} 
                                attributes={{
                                    value: form.emails[index],
                                    onChange: e => {
                                        let emails = form.emails;
                                        emails[index] = e.target.value;
                                        setForm({
                                            ...form,
                                            emails: emails
                                        })
                                    }
                                }}
                                button={
                                    <Button attributes={{
                                        onClick: () => {
                                            let emails = form.emails;
                                            emails.splice(index, 1);
                                            setForm({
                                                ...form,
                                                emails: emails
                                            })
                                        }
                                    }}>
                                        <Icon name="delete" />
                                    </Button>
                                } 
                            />
                        )
                    })}
                </div>
            </div>
            <div className='form__buttons'>
                <Button className={'_default _outline'} attributes={{
                    onClick: () => {
                        setForm(defaultState);
                        theme.setModalEdit(false);
                    }
                }}>
                    Отменить
                </Button>
                <Button className={`_default ${!validate && '_disable' || ''}`} attributes={{
                    onClick: () => {
                        if(statusUpdate == 'IDLE'){
                            let correctEmails = [];

                            if(form.emails && form.emails.length > 0){
                                form.emails.map(email => {
                                    if(email !== ''){
                                        correctEmails.push(email);
                                    }
                                })
                            }

                            dispatch(updateParserItem({
                                body: {
                                    ...form,
                                    emails: correctEmails,
                                    query_items: form.query_items.split(', ')
                                }
                            }))
                        }
                    }
                }}>
                    Обновить
                </Button>
            </div>
        </div>
    )
}

export default Edit;