import {createActions} from "redux-actions";

const {
    getParser,
    getParserSuccess,
    getParserFailure,
    getParserIdle,
    getParserClear,
    setSearch
} = createActions(
    "GET_PARSER",
    "GET_PARSER_SUCCESS",
    "GET_PARSER_FAILURE",
    "GET_PARSER_IDLE",
    "GET_PARSER_CLEAR",
    'SET_SEARCH'
);


export {
    getParser,
    getParserSuccess,
    getParserFailure,
    getParserIdle,
    getParserClear,
    setSearch
};
