import React, { useEffect, useState, useContext } from 'react';

import Select from '../../../components/select';
import Input from '../../../components/input';
import Icon from '../../../components/icon';
import Button from '../../../components/button';

import { useSelector, useDispatch } from 'react-redux';

import { Theme } from '../../../context';
import { work_period, work_period_options } from '../../../config';
import { setSettings, setSettingsIdle } from '../../../modules/initData/actions';

const Setting = () => {

    const theme = useContext(Theme);
    
    const [validate, setValidate] = useState(false);

    const dispatch = useDispatch();

    const setting_work_period = useSelector(state => state.initData.work_period);
    const status_settings = useSelector(state => state.initData.settingStatus);

    useEffect(() => {
        if(status_settings == 'SUCCESS'){
            dispatch(setSettingsIdle());
        }
    }, [status_settings])

    useEffect(() => {
        if(setting_work_period){
            setForm({
                email: 'aljmyhov@yandex.ru',
                period: setting_work_period
            })
        }
    }, [setting_work_period])

    const [form, setForm] = useState({
        email: '',
        period: '',
    });

    useEffect(() => {
        let state = true;
        if(!form.email.length){
            state = false;
        }
        if(!form.period.length){
            state = false;
        }
        setValidate(state);
    }, [form])

    return (
        <div className='form'>
            <div className='form__field'>
                <label>Default email</label>
                <Input attributes={{
                    value: form.email && form.email || '',
                    onChange: (e) => {
                        setForm({
                            ...form,
                            email: e.target.value
                        })
                    }
                }} className={'_default'} placeholder={'Введите название'} />
            </div>
            <div className='form__field'>
                <label>Периодичность запросов</label>
                <Select 
                    options={work_period_options}
                    attributes={{
                    value: form.period,
                    onChange: (e) => {
                        setForm({
                            ...form,
                            period: e.target.value
                        })
                    }
                }} className={'_default'} />
            </div>
            <div className='form__buttons'>
                <Button className={'_default _outline'} attributes={{
                    onClick: () => {
                        setForm({
                            email: 'aljmyhov@yandex.ru',
                            period: setting_work_period,
                        });
                        theme.setModalSetting(false);
                    }
                }}>
                    Отменить
                </Button>
                <Button className={`_default ${( !validate || status_settings == 'LOADING' ) && '_disable' || ''}`} attributes={{
                    onClick: () => {
                        if(status_settings == 'IDLE'){
                            dispatch(setSettings(form));
                        }
                    }
                }}>
                    Сохранить
                </Button>
            </div>
        </div>
    )
}

export default Setting;