import { combineReducers } from "redux";
import { initData } from "./modules/initData/reducer";
import { parser } from "./modules/parser/reducer";
import { parserItem } from "./modules/parserItem/reducer";

export default combineReducers({
  initData,
  parser,
  parserItem
});
