import {handleActions} from "redux-actions";
import {combineReducers} from "redux";
import {
    getInitData,
    getInitDataSuccess,
    getInitDataFailure,
    getInitDataIdle,
    setSettings,
    setSettingsSuccess,
    setSettingsFailure,
    setSettingsIdle,
} from "./actions";

const status = handleActions(
    {
        [getInitDataSuccess]: () => "SUCCESS",
        [getInitData]: () => "LOADING",
        [getInitDataFailure]: () => "FAILURE",
        [getInitDataIdle]: () => "IDLE",
    },
    'IDLE'
);

const count = handleActions(
    {
        [getInitDataSuccess]: (state, action) => action.payload.count.data,
    },
    0
);

const work_period = handleActions(
    {
        [getInitDataSuccess]: (state, action) => action.payload.settings.data,
        [setSettingsSuccess]: (state, action) => action.payload.data,
    },
    null
);

const settingStatus = handleActions(
    {
        [setSettingsSuccess]: () => "SUCCESS",
        [setSettings]: () => "LOADING",
        [setSettingsFailure]: () => "FAILURE",
        [setSettingsIdle]: () => "IDLE",
    },
    'IDLE'
);

const initData = combineReducers({
    status,
    count,
    work_period,
    settingStatus
});

export {initData};
