import './parser.css';

import React, { useContext, useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import Icon from '../../components/icon';
import Button from '../../components/button';

import { Theme } from '../../context';
import { getParser, getParserClear, getParserIdle } from '../../modules/parser/actions';
import { deleteParserItem, deleteParserItemIdle, getReportIdle, getReportResultIdIdle, getReportTimelineIdle } from '../../modules/parserItem/actions';

import ParserItem from './components/parserItem/parserItem';

const Parser = () => {

    const theme = useContext(Theme);

    const dispatch = useDispatch();

    const { status, result } = useSelector(state => state.parser);

    const { count } = useSelector(state => state.initData);

    const { statusStart } = useSelector(state => state.parserItem);

    useEffect(() => {
        if(status == 'SUCCESS'){
            dispatch(getParserIdle());
        }
    }, [status])

    useEffect(() => {
        if(status == 'IDLE'){
            dispatch(getParser({
                offset: result.length
            }));
        }
    }, [])

    useEffect(() => {
        console.log(statusStart);
        if(statusStart == 'SUCCESS'){
            console.log(status);
            if(status == 'IDLE'){
                setTimeout(function(){
                    dispatch(getParserClear());
                    dispatch(getParser({
                        offset: 0
                    }));
                }, 500)
            }
        }
    },[statusStart])

    const { statusDelete, statusReport, dataReport, statusTimeline, reportTimeline, statusResultID, reportResultID } = useSelector(state => state.parserItem);

    useEffect(() => {
        if(statusReport == 'SUCCESS'){
            dispatch(getReportIdle());
            theme.setModalReport(dataReport);
        }
        if(statusReport == 'FAILURE'){
            dispatch(getReportIdle());
        }
    }, [statusReport])

    useEffect(() => {
        if(statusDelete == 'SUCCESS'){
            dispatch(deleteParserItemIdle());
        }
    }, [statusDelete])

    useEffect(() => {
        if(statusTimeline == 'SUCCESS'){
            dispatch(getReportTimelineIdle());
            theme.setModalTimeline(reportTimeline);
        }
    }, [statusTimeline])

    useEffect(() => {
        if(statusResultID == 'SUCCESS'){
            dispatch(getReportResultIdIdle());
            theme.setModalReport(reportResultID);
        }
    }, [statusResultID])

    return (
        <div className='table _parser'>
            <div className='table__row _header'>
                <div className='table__td _name'>
                    Название
                </div>
                <div className='table__td _keywords'>
                    Ключевые слова
                </div>
                <div className='table__td _report'>
                    Посл. отчет об изм.
                </div>
                <div className='table__td _last-change'>
                    Последнее изменение
                </div>
                <div className='table__td _last-scan'>
                    Последний скан
                </div>
                <div className='table__td _date-create'>
                    Дата добавления
                </div>
                <div className='table__td _emails'>
                    Подписки
                </div>
                <div className='table__td _edit'></div>
                <div className='table__td _delete'></div>
                <div className='table__td _start'></div>
            </div>
            {result && result.length > 0 && result.map(item => {
                return (
                    <ParserItem key={item.id} {...item} />
                )
            })}
            {status == 'SUCCESS' && result.length !== count && (
                <div className='table__more'>
                    <Button attributes={{
                        onClick: () => {
                            if(status == 'IDLE'){
                                dispatch(getParser({
                                    offset: result.length
                                }));
                            }
                        }
                    }} className={'_default'}>
                        Показать еще
                    </Button>
                </div>
            )}
        </div>
    )
}

export default Parser;