import {createActions} from "redux-actions";

const {
    getInitData,
    getInitDataSuccess,
    getInitDataFailure,
    getInitDataIdle,
    setSettings,
    setSettingsSuccess,
    setSettingsFailure,
    setSettingsIdle,
} = createActions(
    "GET_INIT_DATA",
    "GET_INIT_DATA_SUCCESS",
    "GET_INIT_DATA_FAILURE",
    "GET_INIT_DATA_IDLE",
    "SET_SETTINGS",
    "SET_SETTINGS_SUCCESS",
    "SET_SETTINGS_FAILURE",
    "SET_SETTINGS_IDLE"
);


export {
    getInitData,
    getInitDataSuccess,
    getInitDataFailure,
    getInitDataIdle,
    setSettings,
    setSettingsSuccess,
    setSettingsFailure,
    setSettingsIdle,
};
