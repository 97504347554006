import { fork } from "redux-saga/effects";
import { getInitDataWatcher } from './modules/initData/saga';
import { getParserWatcher } from './modules/parser/saga';
import { parserItemWatcher } from './modules/parserItem/saga';

export default function* rootSaga() {
  yield fork(getInitDataWatcher);
  yield fork(getParserWatcher);
  yield fork(parserItemWatcher);
}
