import './modal.css';

import React, { useEffect, useState } from 'react';
import Icon from '../icon';
import Button from '../button';

const Modal = ({ children, title = '', state = false, className = '', toggle = () => {} }) => {

    let handleClose = () => {
        toggle(false);
    }

    return (
        <div className={`modal ${state && '_active' || ''} ${className}`}>
            <div className='modal__bg' onClick={e => handleClose()}></div>
            <div className='modal__wrap'>
                <div className='modal__container'>
                    <Button className={'modal__close'} attributes={{
                        onClick: handleClose
                    }}>
                        <Icon name={'close'} width={10} height={10} />
                    </Button>
                    {title && <div className='modal__title'>{title}</div>}
                    {children}
                </div>
            </div>
        </div>
    )
}

export default Modal;